<i18n>
{
  "en": {
		"updateAvailable": {
			"title": "New version of the app is available!",
			"buttonTitle": "Update"
		}
	},
  "fi": {
		"updateAvailable": {
			"title": "Sovelluksen uusi versio on saatavilla!",
			"buttonTitle": "Päivitä"
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Message snackbar -->
		<v-snackbar
			v-model="notifyMessageVisible"
			:timeout="(5000)"
			color="info"
			bottom
			multi-line
		>
			<span v-html="notifyMessage" />
			<template #action="{ attrs }">
				<v-btn
					v-bind="attrs"
					dark
					text
					@click="notifyMessageVisible = false"
				>
					OK
				</v-btn>
			</template>
		</v-snackbar>

		<!-- Error snackbar -->
		<v-snackbar
			v-model="notifyErrorVisible"
			:timeout="(-1)"
			color="error"
			bottom
			multi-line
		>
			<span
				v-html="notifyError"
			/>
			<template
				#action="{ attrs }"
			>
				<v-btn
					v-bind="attrs"
					dark
					text
					@click="notifyErrorVisible = false"
				>
					OK
				</v-btn>
			</template>
		</v-snackbar>

		<!-- App update notification -->
		<v-snackbar
			v-model="updateAvailable"
			:timeout="(-1)"
			color="info"
			bottom
			multi-line
			persistent
		>
			{{ $i18n.t('updateAvailable.title') }}
			<template
				#action="{ attrs }"
			>
				<v-btn
					v-bind="attrs"
					dark
					text
					@click="refresh"
				>
					{{ $i18n.t('updateAvailable.buttonTitle') }}
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Notifications',
	computed: {
		...mapState([
			'notifyMessage',
			'notifyError',
		]),
		notifyMessageVisible: {
			get () {
				return this.$store.state.notifyMessageVisible
			},
			set (val) {
				this.$store.commit('setNotifyMessageVisible', val)
			},
		},
		notifyErrorVisible: {
			get () {
				return this.$store.state.notifyErrorVisible
			},
			set (val) {
				this.$store.commit('setNotifyErrorVisible', val)
			},
		},
		updateAvailable: {
			get () {
				return this.$store.state.updateAvailable
			},
			set (val) {
				this.$store.commit('setUpdateAvailable', val)
			},
		},
	},
	methods: {
		// Refresh page and force cache bust
		refresh () {
			window.location.reload(true)
		},
	},
}
</script>
